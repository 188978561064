import {
  READ_ACTION,
  LEAD_RESOURCE,
} from "@/constants/resources";

export default [
  {
    path: "/escritorios",
    name: "physical-franchise-list",
    component: () =>
      import("@/modules/franchises/views/PhysicalFranchiseList.vue"),
    meta: {
      resource: LEAD_RESOURCE,
      action: READ_ACTION,
      pageTitle: "Escritórios",
    },
  },
  {
    path: "/franquias-digitais",
    name: "franchisees-list",
    component: () => import("@/modules/franchises/views/FranchiseesList.vue"),
    meta: {
      resource: LEAD_RESOURCE,
      action: READ_ACTION,
      pageTitle: "Franquias digitais",
    },
  },
];
